import React, { useContext, useEffect, useState } from 'react';

import { Place } from '../../app/models/Place';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';

import DefaultModal from '../../components/default/DefaultModal';
import DefaultToolBar from '../../components/default/DefaultToolBar';
import ButtonCreate from '../../components/buttons/ButtonCreate';

import { toast } from 'react-toastify';

import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';
import { AppContext } from '../../contexts/AppContext';
import useProductService from '../../app/services/hooks/useProductService';
import { Product, ProductDataForm, ProductFilters } from '../../app/models/Product';
import ProductDataTable from './components/tables/ProductDataTable';
import ProductCreate from './components/ProductCreate';
import ProductEdit from './components/ProductEdit';
import { useHistory } from 'react-router-dom';
import useNavigationPage from '../../hooks/useNavigationPage';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Productos',
        url: '/products',
        isActive: true
    }
];

const Products = () => {
    const history = useHistory();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingGetProducts, getProducts, deleteProduct, activeProduct } = useProductService();
    const [products, setProducts] = useState<ProductDataForm[]>([]);
    const { navigationPage } = useNavigationPage();

    const [productIdEdit, setProductIdEdit] = useState<number>(-1);
    const [showingEdit, setShowingEdit] = useState(false);
    const [showingCreate, setShowingCreate] = useState(false);
    const [totalRows, setTotalRows] = useState<number>(0);

    const [productFilter, setProductFilter] = useState<ProductFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    useEffect(() => {
        reloadTable();
    }, [productFilter]);

    const getAllProducts = () => {
        getProducts(productFilter, {
            onSuccess: (response: ServiceResponse) => {
                setProducts(response.data.products);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });

                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    const showEdit = (productId: number) => {
        setShowingEdit(true);
        setProductIdEdit(productId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setProductIdEdit(-1);
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const reloadTable = () => {
        getAllProducts();
    };

    const destroy = (productId: number) => {
        const _text = 'Está a punto de eliminar el producto #' + productId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando producto...');
                        deleteProduct(productId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El producto no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const active = (productId: number, active: boolean) => {
        activeProduct(productId, active, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });

                reloadTable();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            }
        });
    };

    return (
        <>
            <Breadcrumbs pageSection="Productos" breadcrumbs={breadcrumbs} />

            <DefaultCard>
                <DefaultToolBar
                    left={<ButtonCreate callbackCreate={showCreate} title="Nuevo producto" />}
                />
                <ProductDataTable
                    products={products}
                    edit={showEdit}
                    destroy={destroy}
                    active={active}
                    loading={fetchingGetProducts}
                    filter={productFilter}
                    setFilter={setProductFilter}
                    totalRows={totalRows}
                    callbackSuccess={reloadTable}
                />
            </DefaultCard>

            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear Producto"
                    backdrop={true}
                    showFooter={false}
                >
                    <ProductCreate
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}

            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar Producto"
                    backdrop={true}
                    showFooter={false}
                >
                    <ProductEdit
                        productId={productIdEdit}
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        onError={hideEdit}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default Products;
